<!-- eslint-disable -->
<template>
  <div class="tw-mt-12">
    <div class="tw-flex tw-justify-center">
      <img src="@/assets/img/help.svg" alt="" class="tw-w-80" />
    </div>
    <h5 class="tw-text-center lg:tw-w-3/5 md:tw-w-3/5 tw-mx-auto tw-mt-12">
      Kindly Click on the
      <span class="tw-text-[#03a84e]">green chat head</span> at the bottom right
      corner of this page to start a chat with our support staff.
      <br />
      <span class="tw-text-red-600"
        >It might take a little while for the green chat head to be
        initialized.</span
      >
    </h5>
  </div>
</template>
<script>
/*eslint-disable*/
export default {
  mounted() {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/66549c709a809f19fb3596a9/1hut8667j";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  },
};
</script>
