var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4"},_vm._l((10),function(item){return _c('div',{key:item,staticClass:"tw-bg-gray-100 tw-px-4 tw-py-5 tw-w-full"},[_c('b-skeleton',{attrs:{"width":"85%"}}),_c('b-skeleton',{attrs:{"width":"55%"}})],1)}),0)]},proxy:true}])},[_c('div',[(_vm.items.length > 0)?_c('div',[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4"},_vm._l((_vm.items),function(item,idx){return _c('div',{key:idx,staticClass:"tw-bg-gray-100 tw-p-3 tw-flex tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-gap-2"},[_c('span',{staticClass:"tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-font-weight-bold tw-rounded",class:[
                  item.transaction_type === 'CREDIT'
                    ? 'tw-bg-green-100'
                    : 'tw-bg-red-100',
                ]},[_c('i-icon',{attrs:{"icon":item.transaction_type === 'CREDIT'
                      ? 'carbon:arrow-down'
                      : 'iconoir:arrow-tr'}})],1),_c('span',{staticClass:"tw-flex tw-flex-col"},[_c('span',{staticClass:"tw-font-semibold tw-text-sm tw-break-all"},[_vm._v(" "+_vm._s(`${item.transaction_type} #${item.transaction_id}` + (item.note !== "" ? `~ ${item.note}` : ""))+" ")]),_c('span',{staticClass:"tw-text-xs",domProps:{"innerHTML":_vm._s(item.date_time)}})])]),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-end"},[_c('span',{staticClass:"tw-text-sm tw-font-semibold"},[_vm._v(" "+_vm._s(item.amount + " " + item.wallet_symbol)+" ")]),_c('span',{staticClass:"tw-text-xs text-uppercase"},[_vm._v(_vm._s(item.wallet_id))])])])}),0),_c('div',{staticClass:"tw-flex tw-justify-between tw-mt-3"},[_c('button',{staticClass:"primary-btn",class:{'tw-bg-gray-500': _vm.meta.current_page === 1},attrs:{"disabled":_vm.meta.current_page === 1},on:{"click":function($event){return _vm.changePage('prev')}}},[_vm._v(" previous "+_vm._s(+_vm.page - 1)+" ")]),_c('div',{staticClass:"tw-self-end"},[_c('button',{staticClass:"primary-btn",class:{'tw-bg-gray-500': _vm.meta.last_page === _vm.meta.current_page},attrs:{"disabled":_vm.meta.last_page === _vm.meta.current_page,"click":"changePage('next')"}},[_vm._v(" next "+_vm._s(+_vm.page + 1)+" ")])])])]):_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center"},[_c('i-icon',{attrs:{"icon":"system-uicons:box-open","width":"200"}}),_c('h6',{staticClass:"tw-mt-3 tw-text-red-600"},[_vm._v("No Transactions Found")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }