import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from "./modules/auth";
import Dashboard from "./modules/dashboard"
import store from '@/store'
import { checkTokenExpiration } from '@/plugins/auth';

Vue.use(VueRouter)

const routes = [
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes.concat(Auth, Dashboard)
})

router.beforeEach((to, from, next) => {
  var isLoggedIn = store.getters['auth/getToken'];
  console.log(isLoggedIn, 'kkkk');
  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {

    checkTokenExpiration();
    // Check if the user is authenticated
    if (!isLoggedIn) {
      // Check token expiration when the application loads
      // Redirect to the login page
      next({ path: '/login', query: { redirectFrom: to.fullPath } })
    } else {
      next()
    }
  } else {
    next()
  }
})


export default router
