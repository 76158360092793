import TransactionHistoryVue from "@/views/Dashboard/TransactionHistory.vue";
import PersonalInformation from "@/views/Dashboard/User/PersonalInformation.vue";
import HelpAndSupport from "@/views/Dashboard/HelpAndSupport.vue";
import UserProfile from "@/views/Dashboard/User/IndexPage.vue";
import Security from "@/views/Dashboard/User/SecurityPage.vue";

const Dashboard = () => import("@/views/Dashboard/IndexPage.vue");
const Deposit = () => import("@/views/Dashboard/DepositFunds.vue");
const Withdrawal = () => import("@/views/Dashboard/WithdrawFunds.vue");

const Notification = () => import("@/views/Dashboard/NotificationDetails.vue");
const Referral = () => import("@/views/Dashboard/ReferralPage.vue");

const Swap = () => import("@/views/Dashboard/SwapCurrency.vue");

const Wallet = () => import("@/views/Dashboard/WalletPage.vue");
const Transfer = () => import("@/views/Dashboard/TransferPage.vue");

const AirtimePurchase = () => import("@/views/Dashboard/Utilities/AirtimePurchase.vue");
const DataSubscription = () => import("@/views/Dashboard/Utilities/DataSubscription.vue");


const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      layout: "DashboardLayout",
      parent: 'dashboard',
      header: "dashboard",
      requiresAuth: true
    },
  },

  {
    path: "/transfer",
    name: "transfer",
    component: Transfer,
    meta: {
      layout: "DashboardLayout",
      parent: 'transfer',
      header: "transfer",
      requiresAuth: true
    },
  },

  {
    path: "/referral",
    name: "referral",
    component: Referral,
    meta: {
      layout: "DashboardLayout",
      parent: 'referral',
      header: "referral",
      requiresAuth: true
    },
  },

  {
    path: "/notification/:id",
    name: "notification",
    component: Notification,
    meta: {
      layout: "DashboardLayout",
      parent: 'dashboard',
      header: "notification",
      requiresAuth: true
    },
  },

  {
    path: "/deposit",
    name: "deposit",
    component: Deposit,
    meta: {
      layout: "DashboardLayout",
      parent: 'deposit',
      header: "deposit",
      requiresAuth: true
    },
  },

  {
    path: "/withdraw",
    name: "withdrawal",
    component: Withdrawal,
    meta: {
      layout: "DashboardLayout",
      parent: 'withdrawal',
      header: "withdraw",
      requiresAuth: true
    },
  },

  {
    path: "/swap",
    name: "swap",
    component: Swap,
    meta: {
      layout: "DashboardLayout",
      parent: 'swap',
      header: "swap",
      requiresAuth: true
    },
  },

  {
    path: "/airtime",
    name: "airtime",
    component: AirtimePurchase,
    meta: {
      layout: "DashboardLayout",
      parent: 'dashboard',
      header: "airtime purchase",
      requiresAuth: true
    },
  },

  {
    path: "/data-subscription",
    name: "data-subscription",
    component: DataSubscription,
    meta: {
      layout: "DashboardLayout",
      parent: 'dashboard',
      header: "data subscription",
      requiresAuth: true
    },
  },

  // {
  //   path: "/staking/id",
  //   name: "staking-details",
  //   component: StakingDetails,
  //   meta: {
  //     layout: "DashboardLayout",
  //     parent: 'staking',
  //     header: "staking information"
  //   },
  // },

  {
    path: "/transaction-history",
    name: "transaction-history",
    component: TransactionHistoryVue,
    meta: {
      layout: "DashboardLayout",
      parent: 'transaction-history',
      header: "transaction history",
      requiresAuth: true
    },
  },

  {
    path: "/wallet",
    name: "wallet",
    component: Wallet,
    meta: {
      layout: "DashboardLayout",
      parent: 'wallet',
      header: "my wallets",
      requiresAuth: true
    },
  },

  {
    path: "/user-profile",
    name: "user-profile",
    component: UserProfile, 
    redirect: '/personal-information',
    meta: {
      layout: "DashboardLayout",
      parent: 'personal-information',
      header: "personal-information",
      requiresAuth: true
    },
    children: [
      {
        path: "/personal-information",
        name: "personal-information",
        component: PersonalInformation,
        meta: {
          layout: "DashboardLayout",
          parent: 'personal-information',
          header: "personal-information",
          subParent: 'personal-information',
          requiresAuth: true
        },
      },
      {
        path: "/personal-information/security",
        name: "security",
        component: Security,
        meta: {
          layout: "DashboardLayout",
          parent: 'personal-information',
          header: "security",
          subParent: 'security',
          requiresAuth: true
        },
      },
    ]
  },

  {
    path: "/help-and-support",
    name: "help-and-support",
    component: HelpAndSupport,
    meta: {
      layout: "DashboardLayout",
      parent: 'help-and-support',
      header: "help and support",
      requiresAuth: true
    },
  },
];

// function checkLoggedIn(to, from, next) {
//   var isAuthenticated = false;
//   if (store.getters["auth/isLoggedIn"]) isAuthenticated = true;
//   else isAuthenticated = false;
//   if (!isAuthenticated) {
//     next();
//   } else {
//     next({
//       query: { redirectFrom: to.fullPath },
//     });
//   }
// }

export default routes;
